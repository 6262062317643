import React from "react";
import Xbarat from "../images/logo.png";

const Projects = ({ font }) => {
  const lang = require(`../languages/${font}.json`);
  return (
    <div
      dir={font === "FA" ? "rtl" : "ltr"}
      className="w-full h-full flex flex-col"
    >
      <div className="w-full h-fit flex flex-row gap-x-2 text-2xl ">
        <span className="text-purple">#</span>
        <span className="text-white">{lang["projAndSkill"]}</span>
        <hr className="text-purple flex-1 w-full h-full flex mt-4" />
      </div>
      <div className="flex-1 w-full h-fit flex justify-start items-start flex-row p-5 gap-x-5 overflow-x-scroll">
        <div className="border-2 border-solid border-gray min-h-[18rem]  w-fit h-fit flex flex-col p-2">
          <div className="border-0 border-b-2 border-solid border-gray flex justify-center items-center p-2 text-white">
            {lang["Languages"]}
          </div>
          <div className="flex-1 w-full h-full flex flex-col text-purple">
            <span className="w-full flex justify-start p-2">Java Script</span>
            <span className="w-full flex justify-start p-2">Type Script</span>
            <span className="w-full flex justify-start p-2">Python</span>
            <span className="w-full flex justify-start p-2">SQL</span>
            <span className="w-full flex justify-start p-2">CSS</span>
          </div>
        </div>
        <div className="border-2 border-solid border-gray min-h-[18rem]  w-fit h-fit flex flex-col p-2">
          <div className="border-0 border-b-2 border-solid border-gray flex justify-center items-center p-2 text-white">
            {lang["Frameworks"]}
          </div>
          <div className="flex-1 w-full h-full flex flex-col text-purple">
            <span className="w-full flex justify-start p-2">React JS</span>
            <span className="w-full flex justify-start p-2">Next JS</span>
            <span className="w-full flex justify-start p-2">Tailwind CSS</span>
            <span className="w-full flex justify-start p-2">Axios</span>
          </div>
        </div>

        <div className="flex-1 w-full h-fit flex flex-row justify-center items-center gap-x-10">
          <div className="border-2 border-solid border-gray w-fit h-full min-h-[18rem] min-w-[10rem] flex flex-col p-2">
            <div className="border-b-2 border-gray border-solid p-2 flex justify-center items-center">
              <img alt="" src={Xbarat} className="w-32 h-32" />
            </div>
            <div className="w-full h-fit flex flex-col gap-x-2 text-white">
              <span className="text-wrap"> {lang["XbaratProjectsFirst"]}</span>
              <a
                className="w-full h-fit flex justify-center items-center"
                href="https://xbarat.pro"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="w-fit h-fit flex justify-center pr-0.5 text-yellow">
                  {lang["Xbarat"]}
                </button>
              </a>
              <span> {lang["XbaratProjectsSecond"]}</span>
            </div>
          </div>
          <span className="w-fit h-fit flex justify-center items-center text-white">
            {lang["Continue"]}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Projects;
