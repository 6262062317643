import React, { useState } from "react";
import Final from "../images/Final.png";
import FinalFlip from "../images/FinalFlip.png";
const AboutMe = ({ font }) => {
  const lang = require(`../languages/${font}.json`);
  const [hovered, setHovered] = useState("");
  return (
    <div
      dir={font === "EN" ? "ltr" : "rtl"}
      className="w-full h-full flex flex-col"
    >
      <div className="w-full h-fit flex flex-row gap-x-2 text-2xl ">
        <span className="text-purple">#</span>
        <span className="text-white">{lang["aboutMe"]}</span>
        <hr className="text-purple flex-1 w-full h-full flex mt-4" />
      </div>
      <div className="w-full h-full flex flex-row">
        <div className="w-fit h-full flex flex-col justify-center items-center max-w-lg p-5 text-gray mb-24">
          <span
            className={` transition-all duration-500 ${
              hovered === "first" ? "text-purple font-bold text-lg" : ""
            }`}
            onMouseEnter={() => setHovered("first")}
            onMouseLeave={() => setHovered("")}
          >
            {lang["firstAboutMe"]}
          </span>
          <span
            className={` transition-all duration-500 ${
              hovered === "second" ? "text-purple font-bold text-lg" : ""
            }`}
            onMouseEnter={() => setHovered("second")}
            onMouseLeave={() => setHovered("")}
          >
            {lang["secondAboutMe"]}
          </span>
        </div>
        <div className="flex-1 w-full h-full hidden md:flex flex-row justify-end items-center">
          <div className="w-56 h-72   overflow-hidden">
            <img
              alt="Me"
              src={font === "FA" ? FinalFlip : Final}
              className="w-72 h-96"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
