import { useState } from "react";
import Github from "./images/Github.png";
import Telegram from "./images/Telegram.png";
import Projects from "./pages/Projects";
import Email from "./images/Email.png";
import Home from "./pages/Home";
import Topbar from "./components/Topbar";
import Contacts from "./pages/contacts";
import AboutMe from "./pages/AboutMe";
import Footer from "./pages/Footer";
function App() {
  const [font, setFont] = useState("EN");

  return (
    <>
      <div className="w-screen h-screen overflow-x-hidden overflow-y-scroll hide-scrollbar">
        <div className={`w-full h-full grid grid-cols-12 `}>
          <div className="col-span-12">
            <Topbar font={font} setFont={setFont} />
          </div>
          <div className="hidden md:flex col-span-1 flex-col justify-center items-center">
            <div className="w-fit h-fit animate-moveXInv">
              <a
                href="https://github.com/sinabook"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="w-full h-fit flex justify-center pr-0.5">
                  <img className="w-6 h-6" alt="" src={Github} />
                </button>
              </a>
              <a
                href="https://t.me/Strix_strigidae"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="w-full h-fit flex justify-center pr-0.5">
                  <img className="w-6 h-6" alt="" src={Telegram} />
                </button>
              </a>

              <a
                href="mailto:sinabook20031381@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="w-full h-fit flex justify-center pr-0.5">
                  <img className="w-6 h-6" alt="" src={Email} />
                </button>
              </a>
            </div>
          </div>
          <div className="col-span-12 md:col-span-10 flex flex-col gap-y-10 p-5">
            <Home font={font} />
            <Projects font={font} />
            <AboutMe font={font} />
            <Contacts font={font} />
          </div>
          <div className="hidden w-full h-full md:flex justify-center items-center">
            <svg width={110} height={260} className="animate-moveX">
              <rect width="111" height="260" fill="none" stroke="gray" />
            </svg>
          </div>
          <div className="col-span-12">
            <Footer font={font} />
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
