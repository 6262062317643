import React from "react";
import Slogan from "../components/Slogan";
import Symbol from "../components/Symbol";
const Home = ({ font }) => {
  const lang = require(`../languages/${font}.json`);
  return (
    <div dir={font === "EN" ? "ltr" : "rtl"} className="flex flex-col">
      <div className="flex flex-row ">
        <div className="flex flex-col gap-y-2 ">
          <div className="flex flex-col md:flex-row gap-x-2">
            <span className="text-white font-bold">
              {lang["HomeSloganSinaIs"]}
            </span>
            <span className="text-purple font-bold">
              {lang["HomeSloganWebDesigner"]}
            </span>
            <span className="text-white font-bold">
              {lang["HomeSloganAndA"]}
            </span>
          </div>
          <div className="flex flex-row gap-x-2">
            <span className="text-purple font-bold">
              {lang["HomeSloganFronEndDeveloper"]}
            </span>
          </div>
          <span className="text-gray ">{lang["HomeCraft"]}</span>
        </div>
        <div dir="ltr" className="flex-1 w-fit h-fit flex  justify-end px-5">
          <Symbol
            width={200}
            height={200}
            top={52}
            mar={2}
            color={"#C778DD"}
            strokeSize={1}
            animation={true}
          />
        </div>
      </div>
      <div className="w-full h-fit flex justify-end my-5">
        <div className="w-fit h-full flex flex-row  items-center border-solid border-2 border-white gap-x-2 p-5">
          <div className="bg-purple w-fit h-fit">
            <svg width="10" height="10">
              <rect
                width="10"
                height="10"
                x="00"
                y="0"
                rx="2"
                ry="2"
                fill="none"
              />
            </svg>
          </div>
          <span className="text-white">{lang["Currently"]}</span>
          <span className="text-purple">{lang["Portfolio"]}</span>
        </div>
      </div>
      <div dir="ltr">
        <Slogan />
      </div>
    </div>
  );
};

export default Home;
