import React from "react";
import Comma from "./Comma.js";
const Slogan = () => {
  return (
    <>
      <div className="w-full justify-center h-full flex flex-col text-white font-bold font-mono ">
        <div className="w-fit h-fit relative z-20 top-5 left-5">
          <Comma />
        </div>
        <div className="w-full h-fit rounded-2xl rounded-br-none border-2 border-gray border-solid p-5 ">
          I'm a Delight After My Coffee ☕
        </div>
        <div className="w-full h-fit flex justify-end">
          <div className="w-fit h-fit relative z-20 -top-4 left-16 ">
            <Comma />
          </div>
          <span className="border-2 rounded-2xl rounded-t-none border-gray border-solid border-t-0 p-5 font-mono">
            - Unknown
          </span>
        </div>
      </div>
    </>
  );
};

export default Slogan;
