import React from "react";

const Footer = ({ font }) => {
  const lang = require(`../languages/${font}.json`);
  return (
    <div
      dir={font === "EN" ? "ltr" : "rtl"}
      className="w-full h-full mt-10 flex flex-col"
    >
      <hr className="text-gray" />
      <div className="w-full h-full grid grid-cols-12 list-disc p-5 text-gray">
        <div className="col-span-1"></div>
        <ul className="col-span-10 list-disc text-gray">
          <li>{lang["addressFooter"]}</li>
        </ul>
        <div className="col-span-1"></div>
      </div>
      <div className=" text-gray w-full h-full flex justify-center items-end p-5">
        {lang["Summer"]}
      </div>
    </div>
  );
};

export default Footer;
