import React from "react";

const Loading = ({ font }) => {
  const lang = require(`../languages/${font}.json`);
  return (
    <div className="w-screen h-screen absolute z-40 top-0 left-0 bg-black opacity-1">
      <div className="w-full h-full flex justify-center items-center text-4xl text-white animate-ping">
        {lang["Loading"]}
      </div>
    </div>
  );
};

export default Loading;
